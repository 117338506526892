import cityHallsEndpoints from "./endpoints/cityHall-endpoints";

import apiConfig from "../config/api";
import http from "./http";

const { getCityHall } = cityHallsEndpoints;
const { baseURL } = apiConfig;

const CityHall = {
  async handleGetCityHallInfo(id) {
    const getCityHallInfoUrl = `${baseURL}${getCityHall.GET(id)}`;
    return await http
      .get(
        getCityHallInfoUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default CityHall;
