<template>
  <div id="app">
    <nprogress-container></nprogress-container>
    <nav-bar />
    <aside-menu
      v-if="$auth.isAuthenticated"
      :menu="menu"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :class="{ 'has-secondary': !!menuSecondary }"
    />
    <router-view />
    <aside-right />
    <footer-bar />
    <overlay />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import Overlay from "@/components/Overlay";
import AsideRight from "@/components/AsideRight";
import jwt_decode from "jwt-decode";
import { getInstance } from "@/auth/index";

// Library
import NprogressContainer from "vue-nprogress/src/NprogressContainer";

export default {
  name: "App",
  components: {
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar,
    NprogressContainer,
  },
  created() {
    const authService = getInstance();
    authService.$watch("loading", (isLoading) => {
      if (isLoading === false) {
        authService.getTokenSilently().then((response) => {
          authService.getIdTokenClaims().then((claims) => {});
          var decoded = jwt_decode(response);
          this.$store.commit("user", {
            name: authService.user.email,
            email: authService.user.email,
          });
          this.permissions = decoded.permissions;
          this.$store.commit("addPermissions", decoded.permissions);
          this.$store.commit("addToken", response);
        });
      }
    });
  },
  data() {
    return {
      permissions: [],
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null,
    };
  },
  computed: {
    menu() {
      return [
        "General",
        [
          {
            to: "/",
            icon: "desktop-mac",
            label: "Tablou de bord",
            visible: true,
          },
        ],
        //"Registru Evidenta Vanzari Terenuri Agricole",
        [
          {
            to: "/Sales",
            label: "Registru evidenta vanzari",
            icon: "file-multiple",
            updateMark: false,
            visible:
              this.permissions.includes("agricol") ||
              this.permissions.includes("registratura"),
          },
        ],
        "Registratura",
        [
          {
            to: "/RegistryEntries",
            label: "Registratura",
            icon: "file-multiple",
            updateMark: false,
            visible:
              this.permissions.includes("agricol") ||
              this.permissions.includes("taxe si impozite") ||
              this.permissions.includes("registratura") ||
              this.permissions.includes("urbanism") ||
              this.permissions.includes("asistenta sociala") ||
              this.permissions.includes("contabilitate") ||
              this.permissions.includes("secretar") ||
              this.permissions.includes("achizitii publice") ||
              this.permissions.includes("stare civila"),
          },
        ],
        "Voluntariat",
        [
          {
            to: "/Volunteering",
            label: "Voluntariat",
            icon: "file-multiple",
            updateMark: false,
            visible: this.permissions.includes("registratura"),
          },
        ],
        "Urbanism",
        [
          {
            to: "/Urbanism",
            label: "Urbanism",
            icon: "file-multiple",
            updateMark: false,
            visible:
              this.permissions.includes("registratura") ||
              this.permissions.includes("urbanism"),
          },
        ],
        "Documente",
        [
          {
            to: "/SaleOffers",
            label: "Oferte de vanzare",
            icon: "file-multiple",
            updateMark: false,
            visible: false,
          },
          {
            to: "/BusinessLetters",
            label: "Documente",
            icon: "file-multiple",
            updateMark: false,
            visible: false,
          },
        ],
        "Website",
        [
          {
            to: "/Announcements",
            label: "Anunturi",
            icon: "file-multiple",
            updateMark: false,
            visible: this.permissions.includes("registratura"),
          },
          {
            to: "/SaleOffersAnnouncements",
            label: "Oferte de vanzare",
            icon: "file-multiple",
            updateMark: false,
            visible: false,
          },
          {
            to: "/Messages",
            label: "Mesaje",
            icon: "file-multiple",
            updateMark: true,
            visible: false
          },
        ],
        "Administrare",
        [
          {
            to: "/Users",
            label: "Utilizatori",
            icon: "file-multiple",
            updateMark: true,
            visible: this.permissions.includes("registratura"),
          },
        ],
      ];
    },
    menuBottom() {
      return [];
    },
    ...mapState(["isOverlayVisible"]),
    ...mapState(["user"]),
  },
  methods: {
    menuClick(item) {
      if (item.menuSecondary) {
        this.menuSecondary = item.menuSecondary;
        this.menuSecondaryLabel = item.menuSecondaryLabel
          ? item.menuSecondaryLabel
          : null;
        this.menuSecondaryIcon = item.menuSecondaryIcon
          ? item.menuSecondaryIcon
          : null;

        this.$store.commit("asideActiveForcedKeyToggle", item);
        this.$store.commit("overlayToggle", true);
      } else if (item.action && item.action === "logout") {
        this.$buefy.toast.open({
          message: "Log out clicked",
          type: "is-danger",
          queue: false,
        });
      }
    },
    menuSecondaryCloseClick() {
      this.$store.commit("overlayToggle", false);
    },
    menuSecondaryClose() {
      this.menuSecondary =
        this.menuSecondaryLabel =
        this.menuSecondaryIcon =
          null;
      this.$store.commit("asideActiveForcedKeyToggle", null);
    },
  },
  watch: {
    isOverlayVisible(newValue) {
      if (!newValue) {
        this.menuSecondaryClose();
      }
    },
  },
};
</script>
