<template>
  <!-- Single Card to contain both City Hall and User Information -->
  <div class="card">
    <div class="card-content">
      <!-- City Hall and User Information in Two Columns -->
      <div class="columns is-vcentered">
        <!-- City Hall Information Column -->
        <div class="column is-half">
            <!-- City Hall Information Box -->
            <div class="box">
              <!-- Website Row -->
              <div class="block">
                <p><strong>Website:</strong></p>
                <div>
                  <!-- Check if URL is available, display as a link -->
                  <p v-if="cityHall.website">
                    <a :href="cityHall.website" target="_blank">{{ cityHall.website }}</a>
                  </p>
                  <p v-else>-</p>
                </div>
              </div>

              <!-- CIF Row -->
              <div class="block">
                <p><strong>CIF:</strong></p>
                <div class="is-flex is-align-items-center">
                  <p class="mr-2">{{ cityHall.cif || '-' }}</p>
                </div>
              </div>

              <!-- Cod SIRUTA Row -->
              <div class="block">
                <p><strong>Cod SIRUTA:</strong></p>
                <div class="is-flex is-align-items-center">
                  <p class="mr-2">{{ cityHall.siruta || '-' }}</p>
                </div>
              </div>
            </div>
        </div>

        <!-- User Information Column -->
        <div class="column is-half">
            <!-- User Information Box -->
            <div class="box">
              <!-- Username Row -->
              <div class="block">
                <p><strong>Nume utilizator:</strong></p>
                <p>{{ userName || '-' }}</p>
              </div>

              <!-- Last Login Time Row -->
              <div class="block">
                <p><strong>Ultima conectare:</strong></p>
                <p>{{ lastLoginTimeString || '-' }}</p>
              </div>

              <!-- Last Login IP Row -->
              <div class="block">
                <p><strong>Ultimul IP de conectare:</strong></p>
                <p>{{ lastLoginIpString || '-' }}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    cityHall: {
      type: Object,
      default: null,
    },
  },
  name: "HeroBarMain",
  data() {
    return {
      lastLoginTimeString: null,
      lastLoginIpString: null
    };
  },
  computed: {
    ...mapState(["userName"]),
  }
};
</script>

<style scoped>
/* Ensure the columns are evenly spaced within the card */
.card .columns {
  display: flex;
  justify-content: space-between;
}

.card .box {
  margin-bottom: 1rem;
}

.block {
  margin-bottom: 0.8rem;
}

.is-flex {
  display: flex;
  align-items: center;
}

.mr-2 {
  margin-right: 0.5rem;
}
</style>
